.ant-notification-notice-success {
    .ant-notification-notice-message {
        color: #004D40 !important;
        font-weight: 600;
    }
}

.ant-notification-notice {
    display: flex;
    // justify-content: center;
    align-items: center;
    align-content: center;
}

// Tabs

// .account-tabs .ant-tabs-tab {

// }

.ant-form-item-feedback-icon.ant-form-item-feedback-icon-success,
.ant-form-item-feedback-icon.ant-form-item-feedback-icon-error {
    display: flex;
}

.ant-form-item-explain-error {
    text-align: left;
}

.ant-popover-buttons {
    display: flex;
}

// 

.ant-modal-footer {
    display: flex;
    justify-content: end;
}

// 


.account-tabs .ant-tabs-tab {
    padding-left: 0 !important;
    padding-right: 60px !important;
}

// 

.ant-btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
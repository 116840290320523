@import url("./_layouts.scss");
@import url("./_table.scss");
@import url("./_form.scss");
@import url("./_wallet.scss");
@import url("./_antd.scss");

.ant-layout {
  background-color: #fafbfe !important;
}

// Line added to make the text black for FullCalandar
table.fc-scrollgrid a {
  color: black !important;
}

.fc-event-time {
  display: none;
}

// Login styles

.login-wrapper {
  min-height: 100vh;
}

.login-container {
  max-width: 450px;
  margin: 0 auto;
  // margin-top: 100px;
}

.alert-box {
  max-width: 450px;
}

.card {
  border: 1px solid transparentize(#3282b8, 0.9);
  padding: 26px;
  box-shadow: 0px 4px 24px -8px transparentize(#3282b8, 0.8);
}

h1 {
  font-weight: 700;
}

.hide-astricks label.ant-form-item-required::before {
  display: none !important;
}

// antd

.ant-steps-item-container {
  display: flex;
  align-items: center;
}

.ant-steps-item-icon,
.ant-steps-icon {
  display: flex;
  justify-self: center;
  align-items: center;
}

.ant-modal-confirm-btns {
  display: flex;
}
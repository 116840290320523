.ant-table-cell {
    white-space: nowrap;
}

.ant-table-title {
    font-weight: 700;

}

.ant-table {
    border: 1px solid #eee;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


.ant-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
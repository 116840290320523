.wallet-card {

    .ant-card-meta-title {
        font-weight: 700 !important;
        font-size: 26px;
    }

    .ant-card-cover img {
        width: 160px !important;
        margin: 0 auto !important;
        padding-top: 20px;

    }
}